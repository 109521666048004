.btn-auth {
  margin-left: 15px;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  border: 0;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.btn-number {
  width: 21px;
  height: 21px;
  border-radius: 100%;
  border: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}
.btn-back,
.btn-next-page,
.btn-register {
  margin-top: 20px;
  margin-right: 20px;
  width: 149px;
  height: 50px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #12344d;
  background: #ffffff;
  border: 1px solid #12344d;
  box-sizing: border-box;
  border-radius: 25px;
  cursor: pointer;
}

.btn-next {
  margin-top: 20px;
  width: 360px;
  height: 50px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #12344d;
  background: #ffffff;
  border: 1px solid #12344d;
  box-sizing: border-box;
  border-radius: 25px;
  cursor: pointer;
}
.btn-back-auth {
  position: absolute;
  top: 10px;
  left: 0;
  width: 130px;
  height: 40px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #7c8388;
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.btn-back-vendor {
  margin-right: 20px;
  width: 88px;
  height: 40px;

  background: #ffffff;
  /* Gray 5 */

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  cursor: pointer;
  color: #12344d;
}
.btn-vendor {
  margin-left: 20px;
  width: 150px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #33a1fd;
  cursor: pointer;
}

.btn-vendor-outlined{
  margin-left: 20px;
  width: 150px;
height: 40px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;

font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
text-align: center;

cursor: pointer;
}

.btn-vendor-fill{
  margin-left: 20px;
  cursor: pointer;
  width: 150px;
height: 40px;
background: #33A1FD;
border-radius: 4px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
text-align: center;
border: 0;
color: #FFFFFF;
}

.btn-decrease,
.btn-increase{
  height: 42px;
  width: 42px;
  background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 2px;
position: absolute;
margin-top: 10px;
cursor: pointer;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #000000;
}
.btn-increase{
  right: 0;
}


.btn-vendor-upload,
.btn-vendor-export{
  width:max-content;
height: 40px;
background: #FFFFFF;
border: none;
box-sizing: border-box;
border-radius: 4px;

font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
text-align: center;
color: #1976D2;
cursor: pointer;
}
.btn-vendor-upload::before{
  font-family: fontAwesome;
  content: "\f093";
  color: #1976D2;
  margin-right: 10px;
}
.btn-vendor-export::before{
  font-family: fontAwesome;
  content: "\f019";
  color: #1976D2;
  margin-right: 10px;
}

.btn-decrease::before{
  font-family: fontAwesome;
  content: "\f068";
}
.btn-increase::before{
  font-family: fontAwesome;
  content: "\f067";

}
.btn-back-vendor::before,
.btn-back-auth::before,
.btn-back::before {
  font-family: fontAwesome;
  content: "\f060";
  margin-right: 10px;
}

.btn-next::after,
.btn-next-page::after {
  font-family: fontAwesome;
  content: "\f061";
  margin-left: 10px;
}

.btn-dialog-pass{
  width: 130px;
  height: 40px;
  background: #FFFFFF;
border: 1px solid #33A1FD;
box-sizing: border-box;
border-radius: 4px;
cursor: pointer;
}

.btn-download-loader{
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #ffcd08;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}


/* color */
.navy {
  background: #12344d !important;
  color: #ffffff;
}

.yellow {
  background: #ffcd08;
  color: #12344d;
}
.gray {
  color: #e0e0e0;
}

.red{
  color: #CB605A;
}
.blue-sky{
color: #33A1FD;
}
.blue-sky-bg{
  background-color:#33A1FD ;
  color: #ffff;
}

.bg-red{
  background-color:#CB605A ;
  color: #ffff;
}



@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

