body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .css-ptiqhd-MuiSvgIcon-root{
  color: #ffffff;
} */

.App {
  text-align: center;
}
.header-employee-expense{
  margin-top: 42px;
  padding-top: 45px;
  background-image: url(/static/media/bgEmployeeExpense.1080ff8b.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 145px;
  width: 100%;
}
.header-employee-expense h5{
  margin-top:0 ;
  margin-left: 100px;
  font-family: Quicksand;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 30px;
color: #12344D;
}
.background {
  background-color: #fcfcfc;
}


.select-navbar {
  margin-left: 20px;
  height: 30px;
  width: 228px;
  border-radius: 4px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  color: #012b6f;
}
.select-navbar:hover,
.select-navbar:focus,
.select-navbar:active {
  box-shadow: none;
  outline: none;
}
.navbar-vendor {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #12344d;
}
.info-account-vendor {
  width: -webkit-max-content;
  width: max-content;
  height: 32px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #12344d;
  text-align: center;
  align-self: center;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 20px;
}
/* form PP */
.label-search-form-pp {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #12344d;
  margin-right: 40px;
  margin-top: 10px;
}
.select-search-form-pp-vendor {
  width: 432px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 70px;
}
.select-search-form-pp-prioriy {
  width: 197px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
}
.input-date-search-form-pp {
  width: 197px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
}
.select-data-priority {
  width: 88px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}
/* close form pp */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dialog-changePass{
  padding: 40px 20px;
}

.label-dialog {
  display: flex;
  flex-direction: column !important;
}
.label-dialog label{
  margin-bottom: 28px;
  font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
color: #000000;

}
.label-dialog input{
  padding-left: 15px;
  width: 100%;
height: 34px;
margin-bottom: 10px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
}
.label-dialog input:focus,
.label-dialog input:hover,
.label-dialog input:active
{
  box-shadow: none;
  outline: none;
}

.submit-button{
  width: 130px;
  height: 40px;
  background-color:#33A1FD;
border: 1px solid #33A1FD;
box-sizing: border-box;
border-radius: 4px;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}
.list-error{
  color:#CB605A;
  font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 14px;
padding-left: 15px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* general for auth */
.bg-auth-portal {
  background-image: url(/static/media/Bg_auth_left.cf88e378.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}
.bg-circle-top-left {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.3);
  width: 150px;
  height: 150px;
  border-bottom-right-radius: 100%;
}

.bg-circle-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.3);
  width: 200px;
  height: 300px;
  border-top-left-radius: 100vw;
  border-bottom-left-radius: 100vw;
}
.side-auth {
  /* position: relative; */
  height: 100vh;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-auth-right {
  display: flex;
}
.title-auth-right img {
  margin-top: 18px;
  margin-right: 10px;
}
.title-auth-right p {
  font-family: Gotham Rounded;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.05em;
  color: #12344d;
}
.side-auth-register {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-auth-register h4 {
  align-self: flex-start;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #12344d;
  margin-bottom: 45px;
}
.side-auth-register label {
  align-self: flex-start;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 10px;
}
.side-auth p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}

.input-auth {
  width: 100%;
  height: 42px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  background: #f2f2f2;
  border-radius: 8px;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

.select-auth {
  width: 110% !important;
  height: 42px;
  padding-left: 20px;
  margin-bottom: 25px;
  background: #f2f2f2;
  border-radius: 8px;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}
textarea {
  resize: none;
}
.side-auth input:hover,
.side-auth input:focus,
.side-auth input:active,
.side-auth select:hover,
.side-auth select:focus,
.side-auth select:active,
.side-auth textarea:hover,
.side-auth textarea:focus,
.side-auth textarea:active,
.side-auth-register input:hover,
.side-auth-register input:focus,
.side-auth-register input:active,
.side-auth-register select:hover,
.side-auth-register select:focus,
.side-auth-register select:active,
.side-auth-register textarea:hover,
.side-auth-register textarea:focus,
.side-auth-register textarea:active {
  box-shadow: none;
  outline: none;
}
.label-as {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.label-as p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
.label-as-vendor {
  width: 100px;
  height: 36px;
  margin-left: 20px;
  background: #ffcd08;
  border-radius: 30px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #12344d;
}

.tab-link {
  display: flex;
  margin-top: 20px;
}
.tab-link .active {
  width: 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  padding: 5px;
  border-bottom: 1px solid #12344d;
  text-decoration: none;
}
.tab-link .inactive {
  width: 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  border-bottom: 1px solid #e0e0e0;
  padding: 5px;
  text-align: center;
  color: #bdbdbd;
  text-decoration: none;
}
.tab-number {
  display: flex;
  margin-top: 50px;
}

label.filebutton {
  overflow: hidden;
  position: relative;
  width: 360px;
  height: 32px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  background: #f2f2f2;
  border-radius: 8px;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

label span input {
  z-index: 999;
  line-height: 0;
  /* font-size: 50px; */
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
}
label span img {
  position: absolute;
  right: 20px;
}
.file-name {
  position: absolute;
  right: 30px;
  top: 15px;
}
.dynamic-register {
  justify-content: center;
}

.btn-loading{
  margin-top: 20px;
  margin-right: 20px;
  width: 149px;
  height: 50px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #12344d;
  color: #ffffff;
  border: 1px solid #12344d;
  box-sizing: border-box;
  border-radius: 25px;
  cursor: pointer;
}
.loaderDownload{
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #ffcd08;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.button_btn-auth__1B8BU {
  margin-left: 15px;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  border: 0;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.button_btn-number__1fbbC {
  width: 21px;
  height: 21px;
  border-radius: 100%;
  border: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}
.button_btn-back__2z042,
.button_btn-next-page__1cu8T,
.button_btn-register__3GPTl {
  margin-top: 20px;
  margin-right: 20px;
  width: 149px;
  height: 50px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #12344d;
  background: #ffffff;
  border: 1px solid #12344d;
  box-sizing: border-box;
  border-radius: 25px;
  cursor: pointer;
}

.button_btn-next__3uavz {
  margin-top: 20px;
  width: 360px;
  height: 50px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #12344d;
  background: #ffffff;
  border: 1px solid #12344d;
  box-sizing: border-box;
  border-radius: 25px;
  cursor: pointer;
}
.button_btn-back-auth__1a5Ds {
  position: absolute;
  top: 10px;
  left: 0;
  width: 130px;
  height: 40px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #7c8388;
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.button_btn-back-vendor__9S9Cq {
  margin-right: 20px;
  width: 88px;
  height: 40px;

  background: #ffffff;
  /* Gray 5 */

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  cursor: pointer;
  color: #12344d;
}
.button_btn-vendor__3lzo9 {
  margin-left: 20px;
  width: 150px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #33a1fd;
  cursor: pointer;
}

.button_btn-vendor-outlined__MEyfl{
  margin-left: 20px;
  width: 150px;
height: 40px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;

font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
text-align: center;

cursor: pointer;
}

.button_btn-vendor-fill__26IMn{
  margin-left: 20px;
  cursor: pointer;
  width: 150px;
height: 40px;
background: #33A1FD;
border-radius: 4px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
text-align: center;
border: 0;
color: #FFFFFF;
}

.button_btn-decrease__1grkc,
.button_btn-increase__2loP4{
  height: 42px;
  width: 42px;
  background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 2px;
position: absolute;
margin-top: 10px;
cursor: pointer;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #000000;
}
.button_btn-increase__2loP4{
  right: 0;
}


.button_btn-vendor-upload__3MIaH,
.button_btn-vendor-export__1InFw{
  width:-webkit-max-content;
  width:max-content;
height: 40px;
background: #FFFFFF;
border: none;
box-sizing: border-box;
border-radius: 4px;

font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
text-align: center;
color: #1976D2;
cursor: pointer;
}
.button_btn-vendor-upload__3MIaH::before{
  font-family: fontAwesome;
  content: "\f093";
  color: #1976D2;
  margin-right: 10px;
}
.button_btn-vendor-export__1InFw::before{
  font-family: fontAwesome;
  content: "\f019";
  color: #1976D2;
  margin-right: 10px;
}

.button_btn-decrease__1grkc::before{
  font-family: fontAwesome;
  content: "\f068";
}
.button_btn-increase__2loP4::before{
  font-family: fontAwesome;
  content: "\f067";

}
.button_btn-back-vendor__9S9Cq::before,
.button_btn-back-auth__1a5Ds::before,
.button_btn-back__2z042::before {
  font-family: fontAwesome;
  content: "\f060";
  margin-right: 10px;
}

.button_btn-next__3uavz::after,
.button_btn-next-page__1cu8T::after {
  font-family: fontAwesome;
  content: "\f061";
  margin-left: 10px;
}

.button_btn-dialog-pass__16zbK{
  width: 130px;
  height: 40px;
  background: #FFFFFF;
border: 1px solid #33A1FD;
box-sizing: border-box;
border-radius: 4px;
cursor: pointer;
}

.button_btn-download-loader__2VLHw{
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #ffcd08;
  width: 30px;
  height: 30px;
  -webkit-animation: button_spin__27kZq 2s linear infinite; /* Safari */
  animation: button_spin__27kZq 2s linear infinite;
}


/* color */
.button_navy__3AHFd {
  background: #12344d !important;
  color: #ffffff;
}

.button_yellow__2S8P4 {
  background: #ffcd08;
  color: #12344d;
}
.button_gray__2irrJ {
  color: #e0e0e0;
}

.button_red__GkMyt{
  color: #CB605A;
}
.button_blue-sky__3HQ-H{
color: #33A1FD;
}
.button_blue-sky-bg__1VUnL{
  background-color:#33A1FD ;
  color: #ffff;
}

.button_bg-red__3QMxY{
  background-color:#CB605A ;
  color: #ffff;
}



@-webkit-keyframes button_spin__27kZq {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes button_spin__27kZq {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


.container-vendor {
  padding-top: 70px;
  width: 100%;
}
.header-vendor {
  padding: 30px;
  height: -webkit-max-content;
  height: max-content;
  background: #f8f8f8;
}
.header-vendor-invoice{
  position: fixed;
  background: #f8f8f8;
  padding: 30px;
  width: 94vw;
  z-index: 999;
}
.header-vendor h6,
.header-vendor-invoice h6 {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  margin-top: 0;
  color: #12344d;
}
.header-vendor p,
.header-vendor-invoice p {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: -40px;
  color: #12344d;
}
.header-vendor span,
.header-vendor-invoice span{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
text-align: right;
color: #12344D;
}

.header-vendor h2,
.header-vendor-invoice h2{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 35px;
/* identical to box height */

text-align: right;
-webkit-text-decoration-line: underline;
        text-decoration-line: underline;

color: #12344D;
}

.content-list-ipms{
  padding: 0;
}

.content-list-po,
.content-edit-profile {
  padding: 30px;
}
/* detail po */
.detail-po {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #c4d2ea;
  box-sizing: border-box;
}
.detail-po h5 {
  margin: 0;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: #12344d;
}
.square-table {
  padding-top: 5px;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  border: 1px solid rgba(196, 210, 234, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
}
.detail-info label {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #12344d;
}
.detail-info input {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  height: 42px;
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.download-Quotation{
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
}
.download-Quotation p{
  margin-left: 10px !important;
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px !important;
line-height: 18px;
color: #33A1FD !important;
cursor: pointer;
text-decoration: underline;
}
/* end detail po */
/* start edit profile */
.square {
  padding: 30px;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  border: 1px solid rgba(196, 210, 234, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
}
.square p,
.square-table h2{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
color: #7C8388;
margin: 6px 45px;
}
.square h3 {
  margin-top: 0;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #12344d;
}
.square h5,
.square-table h5{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 41px;
margin: 0;
color: #12344D;
}
.square .label-input {
  display: flex;
  flex-direction: column;
}
.square .label-input label {
  /* margin-right: 40px; */
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #12344d;
}
.square .label-input input,
.square .label-input textarea {
  padding: 10px;
  width: 432px;
  height: 38px;
  margin-bottom: 25px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #7c8388;
}
.square .label-input select {
  padding: 10px;

  width: 432px;
  margin-bottom: 25px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #7c8388;
}
.detail-info input:hover,
.detail-info input:focus,
.detail-info input:active,
.square .label-input input:hover,
.square .label-input input:focus,
.square .label-input input:active,
.square .label-input select:hover,
.square .label-input select:focus,
.square .label-input select:active,
.square .label-input textarea:hover,
.square .label-input textarea:focus,
.square .label-input textarea:active,
.select-create-invoice:hover,
.select-create-invoice:focus,
.select-create-invoice:active,
.input-BilledQty:focus,
.input-BilledQty:hover,
.input-BilledQty:active,
.input-amountType:focus,
.input-amountType:hover,
.input-amountType:active,
.input-instalment:focus,
.input-instalment:hover,
.input-instalment:active,
.input-document-no:focus,
.input-document-no:hover,
.input-document-no:active,
.select-profile-bank:focus,
.select-profile-bank:hover,
.select-profile-bank:active,
.input-profile-bank:focus,
.input-profile-bank:hover,
.input-profile-bank:active,
.text-area-reject-po:focus,
.text-area-reject-po:active,
.text-area-reject-po:hover
{
  box-shadow: none;
  outline: none;
}

.text-area-reject-po{
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
/* end edit profile */

/* start Invoice Submission */
.select-create-invoice{
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding:10px;
  width: 100%;
height: 42px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 2px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #12344D;
}

.input-BilledQty{
  width: 100%;
height: 28px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
margin-left: 10px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */
text-align: center;

color: #12344D;
}
.input-amountType{
  width: 100%;
height: 28px;
background: #FFFFFF;
/* border: 1px solid #E0E0E0; */
border: none;
box-sizing: border-box;
border-radius: 4px;
/* margin-left: 10px; */
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* padding-left: 10px; */

color: #12344D;
}

.input-amountType-warning{
  width: 100%;
height: 28px;
background: #FFFFFF;
border: 1px solid #CB605A;
box-sizing: border-box;
border-radius: 4px;
/* margin-left: 10px; */
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* padding-left: 10px; */

color: #12344D;
}


.input-instalment{
  padding:10px ;
  width: 180px;
height: 28px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */
text-align: center;

color: #12344D;
}
.input-document-no{
  padding:10px ;
  width: 180px;
height: 28px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */
text-align: center;

color: #12344D;
}
.select-item-Type{
  background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
color: #12344D;
}

.text-upload-file{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */

-webkit-text-decoration-line: underline !important;

        text-decoration-line: underline !important;
cursor: pointer;
color: #012B6F;

}

.uploadFile-name{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */

-webkit-text-decoration-line: underline;

        text-decoration-line: underline;

color: #33A1FD;
cursor: pointer;
}
.delete-upload-text{
  margin-left: 40px;
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */

-webkit-text-decoration-line: underline;

        text-decoration-line: underline;

color: #CB605A;
cursor: pointer;
}


.select-profile-bank{
  width: 75%;
height: 28px;
  background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
}

.input-profile-bank{
  width: 75%;
height: 28px;
padding-left: 10px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
}

.btn-for-vendor-outlined{
  margin-left: 20px;
  padding-top: 5px;
  width: 150px;
height: 40px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
text-align: center;
align-items: center;
}
.btn-for-vendor-loading-submit{
  margin-left: 20px;
  padding-top: 5px;
  width: 150px;
height: 40px;
background: #33A1FD;
border: 1px solid #33A1FD;
box-sizing: border-box;
border-radius: 4px;
text-align: center;
align-items: center;
}
.mandatory-doc{
  position: relative;
}
.mandatory-doc::after{
  font-family: fontAwesome;
  content: "\f005";
  font-size: 8px;
  color:#CB605A;
  position: absolute;
  margin-left: 5px;
}





